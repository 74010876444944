import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

const ContentPage = ({ data, location, ...props }) => {
    return (
        <SeoPage
            leadImage={data.img0.childImageSharp.fluid.src}
            location={location}
            title="Que se passe-t-il en cas de troisième infraction pour alcool au volant?"
            description="Nous vous expliquons les conséquences d'être reconnu coupable pour une troisième infraction de conduite avec les facultés affaiblies par l'alcool au Québec !"
            fr={undefined}
            lawType="criminal"
            specialtyText="pour Alcool au volant">
            <p>
                Certaines personnes, malheureusement, n'apprennent pas de leurs
                erreurs et, malgré deux condamnations passées, recommettent
                l'infraction de conduite avec les facultés affaiblies par
                l'alcool.
            </p>
            <p>
                Au Québec, une troisième infraction de ce type entraîne des
                conséquences sévères. La loi est particulièrement stricte pour
                les conducteurs récidivistes. Cette infraction répétée mène à
                des sanctions plus lourdes!
            </p>
            <ul>
                <li>
                    <strong>
                        Dans cet article, nous vous expliquons les conséquences
                        de la conduite avec les facultés affaiblies par l’alcool
                        en mettant l’accent sur les conséquences en cas d’une
                        troisième condamnation!
                    </strong>
                </li>
            </ul>
            <p>
                <Img
                    fluid={data.img0.childImageSharp.fluid}
                    title="Sanctions liées à la conduite avec les facultés affaiblies par l’alcool!"
                    alt=""
                />
            </p>
            <h2>
                Quelles sont les conséquences de conduite avec facultés
                affaiblies par l’alcool?
            </h2>
            <p>
                Pour saisir l'ampleur des conséquences d'une troisième
                infraction, il est essentiel de comprendre les sanctions liées
                aux premières et deuxièmes condamnations. Chaque récidive
                entraîne des sanctions plus sévères!
            </p>
            <p>
                Voici un aperçu des conséquences pour une première, deuxième et
                troisième infraction de conduite avec les facultés affaiblies
                par l’alcool au Québec :
            </p>
            <h3>
                <strong>
                    <strong>Première infraction </strong>
                </strong>
            </h3>
            <p>
                Lorsqu'un individu est reconnu coupable de conduite avec
                facultés affaiblies pour la première fois, les conséquences sont
                tout de même assez importantes.{' '}
            </p>
            <h4>Amende obligatoire</h4>
            <p>
                La loi prévoit une amende minimale obligatoire qui commence à{' '}
                <strong>1 000 $</strong> et peut augmenter selon le taux
                d’alcoolémie détecté dans le sang du conducteur. En effet :
            </p>
            <ul>
                <li>
                    Un taux d'alcoolémie entre 80 et 119 mg d'alcool par 100 ml
                    de sang entraîne une amende de <strong>1 000 $.</strong>
                </li>
                <li>
                    Un taux entre 120 et 159 mg entraîne une amende de{' '}
                    <strong>1 500 $.</strong>
                </li>
                <li>
                    Un taux supérieur à 160 mg peut conduire à une amende de{' '}
                    <strong>2 000 $</strong> ou plus.
                </li>
            </ul>
            <p>
                Le montant de l’amende minimale obligatoire varie en fonction du
                taux d’alcoolémie détecté dans le sang. Cependant, le tribunal
                peut, si les circonstances le justifient, décider d’imposer une
                amende plus élevée.
            </p>
            <h4>
                <strong>
                    <strong>
                        Suspension et révocation de son permis de conduire
                    </strong>
                </strong>
            </h4>
            <p>
                Outre l'amende, l'individu se verra imposer une{' '}
                <strong>suspension obligatoire</strong> de son permis de
                conduire d'une <strong>durée minimale d'un an</strong>.{' '}
            </p>
            <p>
                De plus, le tribunal ordonne la <strong>révocation</strong> du
                permis de conduire pour une période{' '}
                <strong>d’un à trois ans</strong>, en fonction de la gravité de
                l'infraction.
            </p>
            <h4>
                <strong>
                    <strong>Saisie du véhicule du conducteur</strong>
                </strong>
            </h4>
            <p>
                Si le taux d’alcoolémie est égal ou supérieur à 160 mg par 100
                ml de sang, ou si le conducteur{' '}
                <Link to="https://avocatsalcoolauvolant.ca/refus-souffler-alcootest/">
                    <strong>refuse de se soumettre à un alcootest</strong>
                </Link>
                , le véhicule du conducteur sera saisi lors de l’arrestation.{' '}
            </p>
            <p>
                <Img
                    fluid={data.img1.childImageSharp.fluid}
                    title="Saisie du véhicule lors de l’arrestation pour alcool au volant !"
                    alt=""
                />
            </p>
            <p>
                Le véhicule sera confisqué pour une durée de{' '}
                <strong>30 jours</strong>, avec des frais de remorquage et
                d’entreposage à la charge du propriétaire.
            </p>
            <p>
                Dans certaines situations, il est possible de demander une
                mainlevée de la saisie, permettant ainsi au propriétaire du
                véhicule de le récupérer avant la fin de la période de 30 jours.
                Cette demande est valable si le conducteur arrêté pour conduite
                en état d’ébriété n'est pas le propriétaire du véhicule.
            </p>
            <h4>Casier judiciaire</h4>
            <p>
                L’infraction de conduite avec les facultés affaiblies par
                l’alcool sera inscrite au casier judiciaire du contrevenant.{' '}
            </p>
            <h4>Peine d’emprisonnement</h4>
            <p>
                En plus de ces sanctions, un tribunal peut imposer une peine de{' '}
                <strong>prison pouvant aller jusqu'à 10 ans</strong> en fonction
                des circonstances entourant l'infraction, bien que cela soit
                relativement rare pour une première infraction.{' '}
            </p>
            <h4>Installation d’un dispositif antidémarrage</h4>
            <p>
                Le tribunal peut ordonner l'installation d'un dispositif
                antidémarrage dans le véhicule du contrevenant pour une durée
                minimale d'un an. Ce dispositif empêche le véhicule de démarrer
                si de l'alcool est détecté.
            </p>
            <h4>Programmes de réduction du risque</h4>
            <p>
                Le tribunal peut ordonner la participation du contrevenant au
                Programme d’évaluation et de réduction du risque de conduite
                avec les facultés affaiblies ainsi qu’au programme Alcofrein.
            </p>
            <p>
                Ces programmes ont pour objectif d’évaluer les conducteurs, de
                les sensibiliser aux dangers de la conduite en état d’ébriété et
                de promouvoir des comportements de conduite plus sécuritaires.
            </p>
            <h3>
                <strong>
                    <strong>Deuxième infraction</strong>
                </strong>
            </h3>
            <p>
                Les conséquences d’une deuxième infraction de conduite avec
                facultés affaiblies sont bien plus graves!
            </p>
            <p>
                <Img
                    fluid={data.img2.childImageSharp.fluid}
                    title="Conséquences pour deuxième infraction d’alcool au volant!"
                    alt=""
                />
            </p>
            <p>
                Si la deuxième infraction survient dans les{' '}
                <strong>dix ans suivant la première condamnation</strong>, le
                conducteur s'expose à des sanctions beaucoup plus sévères,
                telles que:
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Conséquences</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Explication</strong>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            Suspension, révocation du permis de conduire et
                            interdiction de conduire
                        </p>
                    </td>
                    <td>
                        <p>
                            Dès l’arrestation, la SAAQ impose une{' '}
                            <strong>suspension</strong> immédiate du permis de
                            conduire pour une durée de <strong>90 jours</strong>
                            . Durant cette période, le conducteur est interdit
                            de circuler et ne peut obtenir de permis restreint.
                        </p>
                        <p>
                            En plus de cette suspension, une{' '}
                            <strong>interdiction de conduire</strong> d’au moins{' '}
                            <strong>deux ans</strong> s’applique après la
                            condamnation.
                        </p>
                        <p>
                            Par ailleurs, le tribunal ordonne la
                            <strong> révocation</strong> du permis de conduire
                            pour une période de{' '}
                            <strong>trois à cinq ans</strong>. Toutefois, selon
                            la gravité de l’infraction et les antécédents du
                            contrevenant, cette durée peut être encore plus
                            longue.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            <strong>Saisie du véhicule du conducteur</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            Le véhicule du conducteur sera saisi pendant{' '}
                            <strong>90 jours</strong> à compter de
                            l'arrestation.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Peine d’emprisonnement obligatoire</p>
                    </td>
                    <td>
                        <p>
                            En cas de deuxième condamnation dans un délai de 10
                            ans, le conducteur doit purger un emprisonnement
                            obligatoire d’au moins <strong>30 jours</strong>.{' '}
                        </p>
                        <p>
                            Cette peine peut être prolongée dans les cas plus
                            graves et, selon les circonstances, la durée de
                            l’emprisonnement peut aller jusqu’à 10 ans.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Inscription au casier judiciaire</p>
                    </td>
                    <td>
                        <p>
                            La deuxième infraction de conduite avec les facultés
                            affaiblies par l’alcool sera inscrite au casier
                            judiciaire du contrevenant.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Restrictions sur les véhicules</p>
                    </td>
                    <td>
                        <p>
                            Selon les circonstances, le tribunal peut imposer
                            des restrictions strictes sur les véhicules,
                            interdisant au conducteur d’immatriculer, d’acheter,
                            de louer ou de mettre en circulation un véhicule à
                            son nom pendant une durée déterminée.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Installation d’un dispositif antidémarrage</p>
                    </td>
                    <td>
                        <p>
                            En cas de deuxième condamnation pour conduite avec
                            les facultés affaiblies par l’alcool, le tribunal
                            ordonne l'installation d’un dispositif antidémarrage
                            dans le véhicule du contrevenant à vie. Cependant,
                            le conducteur peut en demander le retrait après dix
                            ans.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Amende</p>
                    </td>
                    <td>
                        <p>
                            Contrairement à une première infraction, aucune
                            amende minimale obligatoire n’est prévue pour une
                            deuxième condamnation.{' '}
                        </p>
                        <p>
                            Toutefois, le tribunal conserve le pouvoir
                            discrétionnaire d’imposer une amende, en plus des
                            autres sanctions prévues, selon la gravité de
                            l’infraction et les circonstances.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Programme d’évaluation</p>
                    </td>
                    <td>
                        <p>
                            Le conducteur peut également être tenu par le
                            tribunal de participer au Programme d’évaluation et
                            de réduction du risque de conduite avec les facultés
                            affaiblies.{' '}
                        </p>
                        <p>
                            Ce programme a pour objectif d’analyser les
                            habitudes de consommation d’alcool du contrevenant
                            et de déterminer si un suivi ou des mesures
                            supplémentaires sont nécessaires pour prévenir
                            d’éventuelles récidives.
                        </p>
                        <p>
                            L’évaluation prend en compte plusieurs aspects,
                            notamment les habitudes de conduite du participant
                            et sa perception des risques liés à la conduite avec
                            les facultés affaiblies par l’alcool ou par toute
                            substance illégale.
                        </p>
                    </td>
                </tr>
            </table>
            <h3>Troisième infraction </h3>
            <p>
                Bien que rare, il arrive que certains individus soient condamnés
                une troisième fois pour conduite avec les facultés affaiblies
                par l'alcool dans un délai de 10 ans. Cette troisième
                condamnation entraîne des sanctions plus sévères, bien que
                comparables à celles de la deuxième condamnation.{' '}
            </p>
            <p>
                <Img
                    fluid={data.img3.childImageSharp.fluid}
                    title="Conséquences pour troisième infraction d’alcool au volant!"
                    alt=""
                />
            </p>
            <p>
                <strong>
                    Voici un aperçu des conséquences détaillées liées à cette
                    troisième infraction:
                </strong>
            </p>
            <h4>Amende et inscription au casier judiciaire</h4>
            <p>
                Une troisième infraction pour conduite avec les facultés
                affaiblies par l’alcool, tout comme une deuxième, peut entraîner
                une amende à la discrétion du juge. Toutefois, aucune amende
                minimale obligatoire n’est imposée, ce qui signifie que le
                tribunal peut choisir d’en imposer une ou non.
            </p>
            <p>
                Cependant, la troisième condamnation pour conduite avec les
                facultés affaiblies par l'alcool sera définitivement inscrite au
                casier judiciaire.
            </p>
            <h4>Peine d’emprisonnement obligatoire</h4>
            <p>
                Le contrevenant reconnu coupable pour une troisième fois de
                cette infraction doit purger une peine d'emprisonnement{' '}
                <strong>d'au moins 120 jours</strong>.{' '}
            </p>
            <p>
                Selon les circonstances et la gravité de l'infraction, la peine
                imposée peut aller jusqu'à 10 ans.
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>
                                Comment éviter la peine d’emprisonnement
                                obligatoire ?
                            </strong>
                        </p>
                        <p>
                            Pour éviter la peine d'emprisonnement obligatoire de
                            120 jours lors d'une troisième condamnation pour
                            conduite avec les facultés affaiblies, il est
                            absolument nécessaire de faire appel à un avocat
                            spécialisé en droit criminel.{' '}
                        </p>
                        <p>
                            Un avocat expérimenté dans ce domaine saura évaluer
                            votre dossier et déterminer s’il est possible ou non
                            d’éviter la peine minimale de prison obligatoire!
                        </p>
                        <p>
                            <strong>
                                Un avocat va négocier avec la poursuite pour
                                éviter le dépôt de l'avis de récidive!
                            </strong>
                        </p>
                        <p>
                            Il est important de comprendre que, pour qu’une
                            peine d’emprisonnement obligatoire soit imposée au
                            contrevenant, le tribunal doit être informé par la
                            poursuite que le contrevenant est un récidiviste de
                            cette infraction. Le tribunal en est informé par le
                            dépôt de l’avis de récidive.
                        </p>
                        <p>
                            Une approche qu’un avocat de la défense peut adopter
                            pour éviter cette peine d’emprisonnement obligatoire
                            est de négocier avec la poursuite, c'est-à-dire avec
                            le procureur de la Couronne, afin qu’il ne dépose
                            pas l’avis de récidive au tribunal.
                        </p>
                        <p>
                            Il se peut que l’avocat parvienne à un compromis
                            avec le procureur pour que, moyennant une
                            reconnaissance de culpabilité de la part du
                            contrevenant, l’avis de récidive ne soit pas déposé,
                            ce qui permettrait d’éviter que le tribunal impose
                            la peine minimale d’emprisonnement obligatoire de
                            120 jours.
                        </p>
                        <p>
                            Il est à noter que cette stratégie peut aussi être
                            utilisée pour une deuxième condamnation, permettant
                            ainsi d’éviter la peine minimale d'emprisonnement
                            obligatoire de 30 jours.
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                <Img
                    fluid={data.img4.childImageSharp.fluid}
                    title="Éviter une peine d’emprisonnement pour alcool au volant !"
                    alt=""
                />
            </p>
            <h4>Suspension et saisie immédiate du permis</h4>
            <p>
                Lors de l'arrestation, le conducteur verra son permis de
                conduire suspendu immédiatement pour une période de{' '}
                <strong>90 jours</strong>.{' '}
            </p>
            <p>
                En plus de cette suspension, lors de l’arrestation, le véhicule
                du conducteur sera également saisi pour une durée{' '}
                <strong>de 90 jours</strong>.
            </p>
            <h4>
                Révocation du permis de conduire et interdiction de conduire
            </h4>
            <p>
                Tout comme pour une deuxième condamnation, après une déclaration
                de culpabilité pour une troisième infraction de conduite avec
                les facultés affaiblies, le permis de conduire du contrevenant
                sera <strong>révoqué</strong> pour une période de{' '}
                <strong>trois à cinq ans</strong>, voire plus, en fonction de la
                décision du tribunal.
            </p>
            <p>
                De plus, une{' '}
                <strong>
                    interdiction de conduire d'une durée minimale de deux ans
                </strong>{' '}
                sera imposée, cette période pouvant être prolongée selon les
                circonstances.
            </p>
            <h4>Installation d'un antidémarreur éthylométrique à vie</h4>
            <p>
                Le tribunal ordonne l'installation d'un antidémarreur
                éthylométrique à vie sur le véhicule du condamné.
            </p>
            <p>
                Contrairement à une deuxième condamnation, il n'est pas possible
                de demander le retrait de cet appareil après 10 ans. Cette
                mesure est permanente.
            </p>
            <h4>
                Interdiction d'immatriculer, acquérir, louer ou mettre en
                circulation un véhicule
            </h4>
            <p>
                Le contrevenant est interdit d'immatriculer, d'acquérir, de
                louer ou de mettre en circulation un véhicule à son nom.{' '}
            </p>
            <h4>Obtention d’un permis restreint</h4>
            <p>
                Il est possible d'obtenir un{' '}
                <Link to="https://avocatsalcoolauvolant.ca/garder-permis-alcool-volant/">
                    <strong>permis restreint</strong>
                </Link>{' '}
                après six mois, à condition que le véhicule soit équipé d'un
                antidémarreur éthylométrique. Le permis restreint permet à la
                personne condamnée de conduire sous certaines conditions.
            </p>
            <p>
                <Img
                    fluid={data.img5.childImageSharp.fluid}
                    title="Obtenez un permis restreint après une infraction d'alcool au volant !"
                    alt=""
                />
            </p>
            <p>
                Si le contrevenant conduit un véhicule sans antidémarreur
                éthylométrique alors qu'il est tenu à cette obligation, il
                risque des sanctions supplémentaires telles que la saisie du
                véhicule, une amende pouvant atteindre 3 000 $ ou une suspension
                de son permis.
            </p>
            <h2>
                Est-il possible de récupérer son permis de conduire après une
                troisième condamnation pour alcool au volant ?
            </h2>
            <p>
                Lorsqu’une personne est reconnue coupable d’une troisième
                infraction de conduite avec les facultés affaiblies par l’alcool
                en moins de 10 ans, elle fait face à des sanctions sévères,
                notamment la révocation de son permis de conduire.{' '}
            </p>
            <p>
                Cependant, il est possible de le récupérer après la période de
                sanction, sous certaines conditions, et selon un processus en
                plusieurs étapes.
            </p>
            <h4>Étape 1 – Réception de la lettre de la SAAQ</h4>
            <p>
                Dès la réception du jugement du tribunal, la personne condamnée
                recevra une lettre de la SAAQ. Cette lettre confirme plusieurs
                informations essentielles, notamment la culpabilité de
                l'individu, la révocation de son permis de conduire, et la date
                à partir de laquelle la sanction prend fin.{' '}
            </p>
            <p>
                Elle précise aussi si la personne est admissible à un permis
                restreint, si cela s'applique, et l'obligation de conduire un
                véhicule équipé d’un antidémarreur éthylométrique à vie.{' '}
            </p>
            <h4>Étape 2 – Admissibilité à la condition X</h4>
            <p>
                Un mois avant la fin de la sanction, la SAAQ enverra une autre
                lettre pour informer la personne qu’elle est admissible à un
                permis assorti de la condition X, stipulant que le conducteur ne
                peut conduire qu’un véhicule équipé d’un antidémarreur
                éthylométrique.
            </p>
            <h4>
                Étape 3 – Location et installation de l’antidémarreur
                éthylométrique
            </h4>
            <p>
                La personne devra ensuite louer et faire installer un
                antidémarreur éthylométrique, à ses frais, conformément aux
                exigences de la SAAQ. Cet appareil est nécessaire pour pouvoir
                conduire à nouveau un véhicule.{' '}
            </p>
            <p>
                Les frais d’installation d’un antidémarreur éthylométrique
                s’élèvent à 50 $ plus taxes, et la location mensuelle est de 61
                $ plus taxes. En outre, des frais supplémentaires peuvent
                s’appliquer.{' '}
            </p>
            <p>
                Si une personne détient un permis assorti de la condition X et
                conduit un véhicule non équipé d’un antidémarreur
                éthylométrique, elle s’expose à des sanctions sévères, notamment
                la saisie immédiate du véhicule pour 30 à 90 jours, une amende
                de 1 500 $ à 3 000 $, ainsi qu’une suspension de permis de trois
                mois ou même sa révocation.
            </p>
            <h4>Étape 4 – Obtention du nouveau permis</h4>
            <p>
                Après avoir satisfait à toutes les exigences, la personne peut
                demander un nouveau permis de conduire. Les frais associés à son
                obtention varient entre 420 $ et 560 $, selon les circonstances
                spécifiques de chaque dossier.
            </p>
            <h2>
                Vous êtes accusé pour la troisième fois de conduite avec les
                facultés affaiblies ? Consultez un avocat spécialisé en droit
                criminel !
            </h2>
            <p>
                Vous êtes accusé pour une troisième fois de conduite avec les
                facultés affaiblies par l’alcool. Les conséquences sont sévères,
                comprenant la suspension et la révocation de votre permis de
                conduire, ainsi qu’une peine d’emprisonnement obligatoire.
            </p>
            <p>
                Dans cette situation, il est absolument nécessaire de consulter
                un avocat spécialisé en droit criminel. Son expertise permettra
                de limiter les impacts d’une troisième condamnation et, dans
                certains cas, d’éviter la peine minimale de 120 jours de prison.
            </p>
            <p>
                <Img
                    fluid={data.img6.childImageSharp.fluid}
                    title="Avocat pour troisième infraction d’alcool au volant!"
                    alt=""
                />
            </p>
            <p>
                Un avocat expérimenté pourra proposer une défense adaptée à
                votre situation et, si les circonstances le permettent, viser un
                acquittement ou, dans le cas contraire, minimiser les
                conséquences de cette troisième condamnation. Ne sous-estimez
                pas l'importance de l’intervention d’un avocat, car cela peut
                véritablement influencer le résultat de votre affaire!
            </p>
            <p>
                <strong>
                    Grâce à notre formulaire simple et gratuit, trouvez
                    rapidement un avocat en droit criminel! Après votre demande,
                    nous vous mettrons en contact avec un avocat près de chez
                    vous.
                </strong>
            </p>
            <p>
                <strong>
                    Ce service est sans engagement et 100 % gratuit, vous
                    permettant de prendre une décision en toute sérénité.
                </strong>
            </p>
        </SeoPage>
    )
}

export default ContentPage

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "troisieme-infraction-alcool-volant/sanctions-conduite-facultes-affaiblies-alcool.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "troisieme-infraction-alcool-volant/saisir-vehicule-arrestation-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(
            relativePath: {
                eq: "troisieme-infraction-alcool-volant/consequences-deuxieme-infraction-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(
            relativePath: {
                eq: "troisieme-infraction-alcool-volant/consequences-troisieme-infraction-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img4: file(
            relativePath: {
                eq: "troisieme-infraction-alcool-volant/eviter-prison-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img5: file(
            relativePath: {
                eq: "troisieme-infraction-alcool-volant/avocat-troisieme-infraction-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img6: file(
            relativePath: {
                eq: "troisieme-infraction-alcool-volant/avocat-troisieme-infraction-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
